import React from 'react';
import { I18NContext } from '../../I18NContext';
import {DeliveryPhoto} from "../component";

interface DeliveryPhotoComponentProps {
    deliveryPhoto?: DeliveryPhoto;
    width: string;
    height: string;
}

class DeliveryPhotoComponent extends React.Component<DeliveryPhotoComponentProps, {}> {

    render() {
        return <pui-section id="PhotoOnDelivery" spacingBottom="medium"
            data-csa-c-item-type="ap-pyo-photo-delivery-element-type"
            data-csa-c-item-id="ap-pyo-photo-delivery-element-id"
            data-csa-c-type="ap-pyo-photo-delivery-element"
            data-csa-c-action="ap-pyo-photo-delivery-element">
            <pui-text input={this.props.deliveryPhoto?.photoUrlAlt}/>
            <img
                src={this.props.deliveryPhoto?.photoUrl}
                alt={this.props.deliveryPhoto?.photoUrlAlt}
                width={this.props.width}
                height={this.props.height}
            />
        </pui-section>
    }
}

DeliveryPhotoComponent.contextType = I18NContext;

export default DeliveryPhotoComponent;